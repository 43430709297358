document.querySelectorAll(".js-cookiemodal").forEach((button) => {
  button.addEventListener("click", (event) => {
    event.preventDefault();
    document
      .querySelector("body")
      .dispatchEvent(new CustomEvent("cookies:update"));
    document.getElementById("cookie-modal").scrollIntoView();
  });
});

document
  .querySelector("body")
  .addEventListener("cookies:saved", () => window.location.reload());
