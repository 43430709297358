import './js/offcanvas.js'
import './js/scroll-effect.js'
import './js/contact.js'
import './js/page-tabs.js'
import './js/cookie.js'
import './js/save-scrollpos.js'
import './js/anchor.js'


document.addEventListener('DOMContentLoaded', () => {
    const { body } = document
    setTimeout(() => {
        body.classList.remove('prevent-animation')
    }, 1000)
})
