document.querySelectorAll("#contact-form").forEach((element) => {

    element.addEventListener("submit", (event) => {
        var url = "https://closedloop.bpaserver.net/cl/cllead?customer=BPA";
        var request = new XMLHttpRequest();

        event.target.style.display = 'none';

        request.open('POST', url, true);
        request.onload = function() { // request successful
            // we can use server response to our request now
            // console.log(request.responseText);
        document.querySelector("#form-success").style.display = 'flex';
        };

        request.onerror = function() {
            // request failed
            event.target.style.display = 'block';
        };

        request.send(new FormData(event.target)); // create FormData from form that triggered event
        event.preventDefault();
    })
})