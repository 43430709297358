var totalDuration = 1000;
const lineTimeline = gsap.timeline();

let story = document.getElementById("story");

function detectMobile() {
  const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });
}

if (story && (window.innerWidth  > 1200 || !detectMobile())) {
  ScrollTrigger.create({
    trigger: ".story",
    start: "top top" - story.height,
    end: "bottom bottom" + story.height,
    // markers: true,
    pin: false,
    scrub: true,
    animation: lineTimeline,
    onUpdate: self => story.style.borderRadius = (0.5 - Math.abs(0.5 - self.progress)) * 170 + "%"
  });
}
