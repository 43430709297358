const links = document.querySelectorAll('.js-tab-link')
const sections = document.querySelectorAll('.js-tab-section')

const toggleTabs = (hash) => {
  if (hash) {
    sections.forEach((section) => {
      section.classList.toggle(
        'is-visible',
        section.isSameNode(document.querySelector(hash))
      )
    })

    links.forEach((link) => {
      link.classList.toggle(
        'is-active',
        link.hash === hash
      )
    })
  }
  else {
    links.item(0).classList.add( 'is-active')
    sections.item(0).classList.add( 'is-visible')
  }


}

if(links.length && sections.length) {
  toggleTabs(window.location.hash)
}
