document.querySelectorAll(".js-scrollpos").forEach((button) => {
  button.addEventListener("click", (event) => {
    window.localStorage.setItem('scrollpos', window.scrollY);
  });
});

document.addEventListener('DOMContentLoaded', () => {
  if(window.localStorage.hasOwnProperty('scrollpos')) {
    window.scrollTo(0, window.localStorage.getItem('scrollpos'))
    window.localStorage.removeItem('scrollpos')
  }
})
