const Observer = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        entry.target.classList.add("in")
        document.querySelector(`[href='#${entry.target.id}']`).classList.add("in")
      } else {
        entry.target.classList.remove("in")
        document.querySelector(`[href='#${entry.target.id}']`).classList.remove("in")
      }
    });
  },
  {
    root: null,
    rootMargin: "-25% 0px",
    tolerance: 200
  }
);

document.querySelectorAll("section").forEach((el) => Observer.observe(el));

const topOfHeader = document.querySelector(".page__header__container").offsetTop; //gets offset of header

if (document.querySelector(".tabset__container")) {
  const topOfTabs = document.querySelector(".tabset__container").offsetTop; //gets offset of header
  document.addEventListener("scroll", ({ target }) => {
    const { scrollTop } = target.documentElement;
    document.querySelector(".tabset__container").classList.toggle("sticky", (scrollTop > (topOfTabs - 133)));
  });
}

document.addEventListener("scroll", ({ target }) => {
  const { scrollTop } = target.documentElement;
  document.querySelector(".page__header__container").classList.toggle("sticky", (scrollTop > (topOfHeader)));
})
