document.querySelectorAll('.js-offcanvas-toggle')
  .forEach((element) => element.addEventListener('click', (event) => {
    event.stopPropagation()
    const offcanvas = document.querySelector('.js-offcanvas')
    const scollIndicator = offcanvas.querySelector('.js-offcanvas-scroll')
    if (!offcanvas) {
      return;
    }

    offcanvas.classList.toggle('is-active')

    document.querySelectorAll('.js-page, .js-offcanvas-close').forEach(element =>
      element.addEventListener('click', () => offcanvas.classList.remove('is-active'), { once: true, bubble: false })
    )


    offcanvas.addEventListener('scroll', ({ target }) => {
      scollIndicator.classList.toggle(
        'is-hidden',
        ((target.scrollTop + target.clientHeight) >= target.scrollHeight - 2)
      )
    })
  }))
